export const getBackPage = (medensureServices, currentService) => {
  const indexOfService = medensureServices?.indexOf(currentService)
  if (indexOfService === 0) return "/medensure/refills/personal-details"

  const indexToFind =
    currentService === "Summary"
      ? medensureServices?.length - 1
      : indexOfService - 1

  switch (medensureServices[indexToFind]) {
    case "Cancel refills":
      return "/medensure/refills/cancel-refills"
    case "Update personal details":
      return "/medensure/refills/update-personal-details"
    case "Upload updated prescription":
      return "/medensure/refills/updated-prescription"
    case "Book a free teleconsult":
      return "/medensure/refills/health-survey"
    default:
      return "/medensure/refills"
  }
}
